/*eslint-disable */

import {bus} from '@/main';
import carousel from 'vue-owl-carousel';
import countries from '@/services/country.json'
import {
  mapGetters
} from 'vuex';
import ngoJoinRequestPopup from '@/components/private/ngo-join-request-popup';

const $ = window.jQuery = require('jquery'); 
export default {
  name: 'ngo-and-support',
  components: {
    carousel,
    'join-request-popup' : ngoJoinRequestPopup
  },
  props: [],
  data () {
    return {
      totalCountOfFilters:[],
      nextPageUrl:'',
      search_term:'',
      userLoading:false,
      sliderTouchDrag:false,
      countryList:[],
      disabledFilters:'',
      filterLoading:false,
      ngoSupportGroupFlag:'ngo',
      supportGroupData:null,
      supportGroupDataResults:[],
      ngoData:null,
      ngoDataResults:[],
      ngoDataNextPageUrl:'',
      supportGroupNextPageUrl:'',
      joinRequestPopupState:false,
      joinId:'',
      supportGroupJoinRequestPopupState:false,
      selfUserId:''
    }
  },
  computed: {
    ...mapGetters([
      'publicNgoListGetters'
    ]),
    getAllNGoListFun(){
     this.ngoData = this.$store.getters.publicNgoListGetters.data
     return;
    },
    SetIntialStatus() {
      if (this.ngoData) {
        this.nextPageUrl = this.ngoData.next
        this.ngoDataResults = this.ngoData.data
      }
      return;
    },
  },
  mounted () {
    this.getAllNGoListFun
    this.SetIntialStatus
    if(localStorage.getItem('LoginedUserID')){
      this.selfUserId = localStorage.getItem('LoginedUserID')
    }
    bus.$on('joinRequestRecordModalBus', (data) => {
      this.joinRequestPopupState = data
    });
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    $(document).ready(function(){
      $(".member_search_filter_label .form-control").click(function(){
        $('.member_search_filter_label').addClass("show_search")
      })
      $(document).click(function(event) {
        if (!$(event.target).closest(".member_search_filter_label").length) {
          this.isOpen = false;
          $('.member_search_filter_label').removeClass("show_search")
        }
      });
    })
  },
  methods: {
    joinRequestPopupForSGOpenMethod(id){
      this.joinId = id
      document.body.classList.add('popup_open_body')
      this.supportGroupJoinRequestPopupState = true
    },
    joinRequestPopupOpenMethod(id){
      this.joinId = id
      document.body.classList.add('popup_open_body')
      this.joinRequestPopupState = true
    },
    getCountryFlag: function(countryCode, type) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        if (type == 'flag') {
          country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
        } else {
          country = country.name
        }
      } else {
        country = ''
      }
      return country;
    },
    validateSearchDoctorBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          if(this.search_term.length >= 3){
            this.$store.dispatch('doctorUserSearchFun', { search_term: this.search_term }).then((response) => {
              this.ngoDataResults = []
              this.ngoDataResults = response.data.data.data
              this.ngoDataNextPageUrl = response.data.data.data.next
            })
          }else if(this.search_term.length == 0){
            this.$store.dispatch('getAllSupportGroupListFunc').then((response) => {
              this.supportGroupDataResults = []
              this.supportGroupDataResults = response.data.data.data
              this.supportGroupNextPageUrl = response.data.data.data.next
            }).catch((err) => {
            })
          }
        }
      })
    },
  },
  watch:{
    getAllNGoListFun(){
      return this.$store.getters.publicNgoListGetters
    },
    SetIntialStatus() {
      if (this.ngoData) {
        this.nextPageUrl = this.ngoData.next
        this.ngoDataResults = this.ngoData.data
      }
      return;
    },
  }
}